import React from "react";
import Dsgvo from "../../components/dsgvo";
import Layout from "../../components/Layout";

export default () => {
  return (
    <Layout location={"privacy"}>
      {/* <Dsgvo/> */}
    </Layout>
  );
};
